<template>
  <div class="text-end">
    <v-menu
      v-model="isOpenForm"
      :close-on-click="false"
      :close-on-content-click="false"
      :nudge-width="400"
      max-width="28.125rem"
      offset-y
      transition="slide-x-transition"
      bottom
      right
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="btn-position"
          color="blue"
          dark
          v-bind="attrs"
          v-on="on"
          icon
          :small="$vuetify?.breakpoint?.smAndDown"
        >
          <v-icon x-large>mdi-cog-sync</v-icon>
        </v-btn>
        <!-- {{ on }} {{ attrs }} -->
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Credentials Settings</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn rounded @click="resetCredentials">
                Reset
                <v-icon>mdi-restart</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-form ref="credentialsForm" v-model="isFormValid">
              <v-row>
                <v-col cols="12" class="settings-field">
                  <v-text-field
                    v-model="questionnaireId"
                    label="Your questionary ID"
                    placeholder="0375d82e-d722-4ebb-b3f1-a0a1b56662de"
                    outlined
                    dense
                    :rules="[(v) => !!v || 'Questionnaire ID is required']"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" class="settings-field">
                  <v-text-field
                    v-model="projectKey"
                    label="Project Key"
                    placeholder="tdRtap_kidqg_nb_ix8E7qWtoSYcsDvxSD3TP3tWV-8="
                    outlined
                    dense
                    :rules="[(v) => !!v || 'Project Key is required']"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" class="settings-field">
                  <v-text-field
                    v-model="apiKey"
                    label="API Key"
                    placeholder="gAAAAABmg5l9JArpBq00a9GeXMk0keaG22RitslKGC9IJcqr32RQA5wzzGPvFE50a8Ff5tqpuA2E0b8LmqvqmMMd3bFIAlEfRqzR6mJoWY9__z_B_Saw2uKjCcsZ477qvl3ezPzBULUB"
                    outlined
                    dense
                    :rules="[(v) => !!v || 'API Key is required']"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="settings-field">
                  <v-text-field
                    v-model="recommendationCount"
                    label="Recommendation Count value"
                    placeholder="5"
                    outlined
                    dense
                    :rules="[
                      (v) => !!v || 'Recommendation Count value is required',
                    ]"
                  ></v-text-field>
                </v-col>
                <CustomAlert
                  v-if="alert.isShow"
                  :alertType="alert.type"
                  :alertMessage="alert.message"
                />
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="closeCredentialsForm"> Cancel </v-btn>
          <v-btn color="primary" text @click="saveAndCloseCredentialsForm">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import {
  CREDENTIALS_FORM_DATA,
  TOGGLE_CREDENTIALS_FORM,
  IS_VALID_CREDENTIALS_FORM,
} from "@/store/action_types";
import CustomAlert from "./alert/CustomAlert.vue";
// import { generateComputedProperties } from "@/helpers/map_fields";

export default {
  name: "CredentialsForm",
  components: {
    CustomAlert,
  },
  data() {
    return {
      isFormValid: false,

      //   questionnaireId: "",
      //   projectKey: "",
      //   apiKey: "",
      //   recommendationCount: "",

      alert: {
        isShow: false,
        type: "",
        message: "",
      },
    };
  },
  computed: {
    ...mapGetters("credentials_form", [
      "isOpenCredentialsForm",
      "credentialsFromData",
    ]),
    ...mapState({
      isOpen: (state) => state.isOpenCredentialsForm,
    }),
    isOpenForm: {
      get() {
        return this.isOpenCredentialsForm;
      },
      set(value) {
        this.TOGGLE_CREDENTIALS_FORM({ isOpen: value });
      },
    },
    questionnaireId: {
      get() {
        return this.credentialsFromData.questionnaireId;
      },
      set(value) {
        this.CREDENTIALS_FORM_DATA({ data: { questionnaireId: value } });
      },
    },
    projectKey: {
      get() {
        return this.credentialsFromData.projectKey;
      },
      set(value) {
        this.CREDENTIALS_FORM_DATA({ data: { projectKey: value } });
      },
    },
    apiKey: {
      get() {
        return this.credentialsFromData.apiKey;
      },
      set(value) {
        this.CREDENTIALS_FORM_DATA({ data: { apiKey: value } });
      },
    },
    recommendationCount: {
      get() {
        return this.credentialsFromData.recommendationCount;
      },
      set(value) {
        this.CREDENTIALS_FORM_DATA({ data: { recommendationCount: value } });
      },
    },

    // ...generateComputedProperties("credentials_form", [
    //   "questionnaireId",
    //   "projectKey",
    //   "apiKey",
    //   "recommendationCount",
    // ]),
  },
  methods: {
    ...mapActions("credentials_form", [
      TOGGLE_CREDENTIALS_FORM,
      CREDENTIALS_FORM_DATA,
      IS_VALID_CREDENTIALS_FORM,
    ]),

    closeCredentialsForm() {
      if (this.$refs.credentialsForm) {
        this.$refs.credentialsForm.resetValidation();
      }
      this.TOGGLE_CREDENTIALS_FORM({ isOpen: false });
      console.log("Hitting cancel button...");
    },

    saveAndCloseCredentialsForm() {
      // Checking the form is valid or not. if not, then return.
      if (!this.isFormValid && !this.$refs.credentialsForm.validate()) {
        return;
      }

      // Set the item in localStorage
      localStorage.setItem(
        "credentialsFromData",
        JSON.stringify(this.credentialsFromData)
      );

      // Check if the item was successfully set
      let isSaved = localStorage.getItem("credentialsFromData") !== null;
      if (isSaved) {
        this.showAlertMessage("success", "Credentials saved successfully!");
        // Hide the alert after 3 seconds and close the form
        setTimeout(() => {
          this.alert.isShow = false;
          this.TOGGLE_CREDENTIALS_FORM({ isOpen: false });
          this.isOpenForm = this.isOpenCredentialsForm;
        }, 3000);
      } else {
        this.showAlertMessage(
          "error",
          "Failed to save credentials! Please try again."
        );
      }

      // Update the form validation global state
      this.IS_VALID_CREDENTIALS_FORM({
        isValid: this.$refs.credentialsForm.validate(),
      });
    },

    resetCredentials() {
      // Check if all form fields are empty
      const isAllFieldsEmpty =
        !this.questionnaireId &&
        !this.projectKey &&
        !this.apiKey &&
        !this.recommendationCount;

      if (isAllFieldsEmpty) {
        alert("There is no credentials to remove!");
        return;
      }

      if (localStorage.getItem("credentialsFromData") !== null) {
        // Clear localStorage
        localStorage.removeItem("credentialsFromData");

        // Clear Vuex State
        this.CREDENTIALS_FORM_DATA({
          // Production credentials
          data: {
            questionnaireId: "be522028-ce00-413b-af0d-a3d4565c1981",
            projectKey: "tdRtap_kidqg_nb_ix8E7qWtoSYcsDvxSD3TP3tWV-8=",
            apiKey:
              "gAAAAABmg5l9JArpBq00a9GeXMk0keaG22RitslKGC9IJcqr32RQA5wzzGPvFE50a8Ff5tqpuA2E0b8LmqvqmMMd3bFIAlEfRqzR6mJoWY9__z_B_Saw2uKjCcsZ477qvl3ezPzBULUB",
            recommendationCount: "3",
          },
          // Local v4 credentials
          /* data: {
            questionnaireId: "29cf84a4-a209-4530-83c7-b3f981c377ea",
            projectKey: "95U33qcAzxTgzb4e5SxZMpwtiiLnV1Rcbolh8bbtRp4=",
            apiKey:
              "gAAAAABncAlMITmY_Hu8NHpHJcJ1UQXikTo8PJn2vk00esB86BE_LOiAKtbcMsyVKS9BMGkxLGZCxEJS5EOHYLEAY8fYMM5ZBXgRtmAHsgStRphZNTYo5LRPRN5qf-F3f2_GaluqYdUJ",
            recommendationCount: "3",
          }, */
        });
        // window.location.reload();
      } else {
        this.CREDENTIALS_FORM_DATA({
          // Production credentials
          data: {
            questionnaireId: "be522028-ce00-413b-af0d-a3d4565c1981",
            projectKey: "tdRtap_kidqg_nb_ix8E7qWtoSYcsDvxSD3TP3tWV-8=",
            apiKey:
              "gAAAAABmg5l9JArpBq00a9GeXMk0keaG22RitslKGC9IJcqr32RQA5wzzGPvFE50a8Ff5tqpuA2E0b8LmqvqmMMd3bFIAlEfRqzR6mJoWY9__z_B_Saw2uKjCcsZ477qvl3ezPzBULUB",
            recommendationCount: "3",
          },

          // Local v4 credentials
          /*  data: {
            questionnaireId: "29cf84a4-a209-4530-83c7-b3f981c377ea",
            projectKey: "95U33qcAzxTgzb4e5SxZMpwtiiLnV1Rcbolh8bbtRp4=",
            apiKey:
              "gAAAAABncAlMITmY_Hu8NHpHJcJ1UQXikTo8PJn2vk00esB86BE_LOiAKtbcMsyVKS9BMGkxLGZCxEJS5EOHYLEAY8fYMM5ZBXgRtmAHsgStRphZNTYo5LRPRN5qf-F3f2_GaluqYdUJ",
            recommendationCount: "3",
          }, */
        });
      }
    },

    getCredentialsFromLocalStorage() {
      let isSaved = localStorage.getItem("credentialsFromData") !== null;
      if (isSaved) {
        console.log(
          "Loaded credentials from localStorage:",
          JSON.parse(localStorage.getItem("credentialsFromData"))
        );
        this.CREDENTIALS_FORM_DATA({
          data: JSON.parse(localStorage.getItem("credentialsFromData")),
        });
      }
    },

    showAlertMessage(type, message) {
      this.alert.isShow = true;
      this.alert.type = type;
      this.alert.message = message;
    },
  },

  watch: {
    isFormValid: {
      handler(newValue) {
        this.IS_VALID_CREDENTIALS_FORM({ isValid: newValue });
      },
      deep: true,
    },
  },

  created() {
    this.getCredentialsFromLocalStorage();
  },
};
</script>
<style scoped>
.settings-field {
  padding: 0;
}
.btn-position {
  margin-right: 1.5rem;
}
</style>
