var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"d-flex flex-column flex-sm-row",attrs:{"elevation":"0","outlined":""}},[_c('div',{staticClass:"d-flex justify-center",style:({
        width: _vm.$vuetify.breakpoint.xs ? 'auto' : '12.625rem',
        height: _vm.$vuetify.breakpoint.xs ? '10.5rem' : '12.5rem',
        padding: '1rem 0px',
      })},[_c('v-img',{staticClass:"p-4",attrs:{"src":_vm.image,"alt":_vm.name,"contain":""}})],1),_c('div',{staticClass:"d-flex flex-column flex-grow-1 pa-4"},[_c('v-card-title',{staticClass:"pb-1 pt-0"},[_c('div',{staticClass:"font-weight-bold",class:[_vm.$vuetify.breakpoint.xs ? 'text-subtitle-2' : 'text-h6']},[_vm._v(" "+_vm._s(_vm.name)+" ")])]),_c('v-card-text',{staticClass:"py-1 flex-grow-1 d-flex flex-column"},[_c('div',{staticClass:"mb-1",class:[
            _vm.$vuetify.breakpoint.xs ? 'text-body-2' : 'text-subtitle-1',
          ]},[_vm._v(" "+_vm._s(`¥${_vm.formatPrice(_vm.price)}`)+" ")]),_c('div',{class:[_vm.$vuetify.breakpoint.xs ? 'text-caption' : 'text-body-2']},[_c('div',{staticClass:"mb-1"},[_c('span',{staticClass:"font-weight-bold"}),_vm._v(" "+_vm._s(`${_vm.tasteType} ${ _vm.ingredientType.length > 0 ? `(${_vm.ingredientType})` : "" }`.trim())+" ")]),_c('div',{staticClass:"mb-1"},[_c('span',[_vm._v("オススメの飲み方:")]),_vm._v(" "+_vm._s(`${_vm.recommendedWayToDrink}`)+" ")])])]),_c('v-card-actions',{staticClass:"py-0"},[_c('v-spacer'),_c('v-btn',{staticStyle:{"background-color":"#1e88e5","opacity":"0.9"},attrs:{"color":"white","text":"","small":_vm.$vuetify?.breakpoint?.smAndDown},on:{"click":_vm.openProductDetailsDialog}},[_vm._v(" details ")])],1)],1)]),_c('ProductDetails',{attrs:{"isOpenDialog":_vm.showDialog,"selectedProduct":_vm.productDetails},on:{"update:isOpenDialog":function($event){_vm.showDialog=$event},"update:is-open-dialog":function($event){_vm.showDialog=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }