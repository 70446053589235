<template>
  <v-dialog
    :value="isOpenDialog"
    @input="updateDialog"
    max-width="600px"
    scrollable
  >
    <v-card>
      <v-card-actions class="pt-4 pb-0">
        <v-spacer></v-spacer>
        <v-icon @click="closeProductDetailsDialog" class="cursor-pointer"
          >mdi-close</v-icon
        >
      </v-card-actions>
      <div
        class="flex-shrink-0"
        :style="{
          height: $vuetify.breakpoint.xs ? '10.5rem' : '15.625rem',
          padding: '0px 24px 10px 24px',
        }"
      >
        <v-img
          :src="selectedProduct.image"
          :alt="selectedProduct.name"
          height="100%"
          contain
        ></v-img>
      </div>
      <v-card-title>
        <span class="text-xs-subtitle-1">{{ selectedProduct.name }}</span>
      </v-card-title>

      <v-card-text>
        <div class="text-h6 mb-2">
          {{ `¥${selectedProduct.price}` }}
        </div>
        <div class="mb-2">
          <p class="mb-0">
            <span class="font-weight-bold">味の種類:</span
            >{{ ` ${selectedProduct.tasteType}` }}
          </p>
          <p class="mb-0">
            <!-- <span class="font-weight-bold">製造区分:</span> -->
            <span class="font-weight-bold">酒の種類:</span>
            {{
              ` ${
                selectedProduct.ingredientType.length > 0
                  ? selectedProduct.ingredientType
                  : "Unknown"
              }`.trim()
            }}
          </p>
          <p class="mb-0">
            <span class="font-weight-bold">オススメの飲み方:</span>
            {{ `${selectedProduct.recommendedWayToDrink}` }}
          </p>
        </div>

        <div class="text-body-2 text-justify">
          <span class="font-weight-bold">製品説明:</span>
          {{ selectedProduct.description }}
        </div>

        <div>
          <div class="mt-2">
            <p class="font-weight-bold text-center">フードペアリング</p>
          </div>

          <div>
            <!-- <p class="mb-0">
              <span class="font-weight-bold">和食:</span>
              {{
                `${
                  selectedProduct.recommendedJapaneseFood.length > 0
                    ? selectedProduct.recommendedJapaneseFood
                    : "Unknown"
                }`.trim()
              }}
            </p>
            <p class="mb-0">
              <span class="font-weight-bold">洋食:</span>
              {{
                `${
                  selectedProduct.recommendedWesternFood.length > 0
                    ? selectedProduct.recommendedWesternFood
                    : "Unknown"
                }`.trim()
              }}
            </p>
            <p class="mb-0">
              <span class="font-weight-bold">中華・エスニック:</span>
              {{
                `${
                  selectedProduct.recommendedChineseAndEthnicFood.length > 0
                    ? selectedProduct.recommendedChineseAndEthnicFood
                    : "Unknown"
                }`.trim()
              }}
            </p>
            <p class="mb-0">
              <span class="font-weight-bold">スイーツ・フルーツ:</span>
              {{
                `${
                  selectedProduct.recommendedSweetsAndFruit.length > 0
                    ? selectedProduct.recommendedSweetsAndFruit
                    : "Unknown"
                }`.trim()
              }}
            </p> -->
            <div>
              <p class="mb-0">
                <span class="font-weight-bold">和食:</span>
              </p>
              <ul>
                <li
                  v-for="(
                    item, index
                  ) in selectedProduct.recommendedJapaneseFood"
                  :key="index"
                >
                  {{
                    `${
                      selectedProduct.recommendedJapaneseFood.length > 0
                        ? item
                        : "Unknown"
                    }`
                  }}
                </li>
              </ul>
            </div>
            <div>
              <p class="mb-0">
                <span class="font-weight-bold">洋食:</span>
              </p>
              <ul>
                <li
                  v-for="(
                    item, index
                  ) in selectedProduct.recommendedWesternFood"
                  :key="index"
                >
                  {{
                    `${
                      selectedProduct.recommendedWesternFood.length > 0
                        ? item
                        : "Unknown"
                    }`
                  }}
                </li>
              </ul>
            </div>
            <div>
              <p class="mb-0">
                <span class="font-weight-bold">中華・エスニック:</span>
              </p>
              <ul>
                <li
                  v-for="(
                    item, index
                  ) in selectedProduct.recommendedChineseAndEthnicFood"
                  :key="index"
                >
                  {{
                    `${
                      selectedProduct.recommendedChineseAndEthnicFood.length > 0
                        ? item
                        : "Unknown"
                    }`
                  }}
                </li>
              </ul>
            </div>
            <div>
              <p class="mb-0">
                <span class="font-weight-bold">スイーツ・フルーツ:</span>
              </p>
              <ul>
                <li
                  v-for="(
                    item, index
                  ) in selectedProduct.recommendedSweetsAndFruit"
                  :key="index"
                >
                  {{
                    `${
                      selectedProduct.recommendedSweetsAndFruit.length > 0
                        ? item
                        : "Unknown"
                    }`
                  }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ProductDetails",
  props: {
    isOpenDialog: {
      type: Boolean,
      required: true,
    },
    selectedProduct: {
      type: Object,
      required: true,
    },
  },
  methods: {
    closeProductDetailsDialog() {
      this.$emit("update:isOpenDialog", false);
    },
    updateDialog(value) {
      this.$emit("update:isOpenDialog", value);
    },
  },
};
</script>

<style scoped></style>
