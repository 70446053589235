<template>
  <div>
    <SnackbarNotifier />
    <div>
      <template>
        <CredentialsForm />
        <v-container>
          <v-row class="d-flex justify-center"
            ><v-btn
              @click="getStartedWithQuestionnaire"
              class="ma-2"
              outlined
              color="blue"
              :small="$vuetify?.breakpoint?.smAndDown"
            >
              Get Started
            </v-btn></v-row
          >
        </v-container>
      </template>
    </div>
    <CircularLoader :isLoading="isLoading" />
    <v-dialog
      v-model="isOpenQuestionnaireDialog"
      :persistent="true"
      width="900"
    >
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn color="blue lighten-2" dark v-bind="attrs" v-on="on" :disabled="isLoading">
          Get Started
        </v-btn>
      </template> -->

      <v-card class="questionnaire-container">
        <v-card-title
          class="d-flex justify-between grey lighten-2 mb-2 questionnaire-title"
        >
          {{ questionnaireName }}
          <v-spacer></v-spacer>
          <v-icon
            @click="isOpenQuestionnaireDialog = false"
            class="cursor-pointer"
            >mdi-close</v-icon
          >
        </v-card-title>

        <v-card-text
          :class="[
            'hvr-shutter-out-horizontal',
            { active: isShowTasteProfileSectionMessage },
          ]"
        >
          <v-progress-linear
            :value="displayProgress"
            class="mb-0 mt-0"
            height="15"
            rounded
          >
            <strong>{{ `${Math.round(displayProgress)}%` }}</strong>
          </v-progress-linear>

          <v-form ref="stepperForm" v-model="isStepperFormValid">
            <v-stepper :elevation="0" :min-height="480" v-model="currentStep">
              <v-stepper-items>
                <v-stepper-content
                  v-for="(step, index) in steps"
                  :key="index"
                  :step="index"
                >
                  <div v-if="currentStep === index">
                    <p class="question-text">
                      {{ `${index + 1}. ${step.question}` }}
                    </p>
                    <v-text-field
                      v-if="step.type === 'text'"
                      :label="step.question"
                      v-model="
                        questionnaireFormData[step.section_name][step.key]
                      "
                      class="option-text"
                    ></v-text-field>
                    <v-radio-group
                      class="option-text"
                      v-else-if="step.type === 'radio'"
                      dense
                      v-model="
                        questionnaireFormData[step.section_name][step.key]
                      "
                      :rules="[
                        (v) => !!v || 'At least one option must be selected!',
                      ]"
                    >
                      <v-radio
                        v-for="(option, key) in step.options"
                        :key="key"
                        :label="option"
                        :value="key"
                      ></v-radio>
                    </v-radio-group>

                    <v-container v-else-if="step.type === 'checkbox'" fluid>
                      <v-checkbox
                        class="option-text"
                        v-for="(option, key, index) in step.options"
                        :key="key"
                        multiple
                        dense
                        :hide-details="
                          index !== Object.keys(step.options).length - 1
                        "
                        :label="option"
                        :value="key"
                        v-model="
                          questionnaireFormData[step.section_name][step.key]
                        "
                        :rules="[
                          (v) =>
                            (v && v.length > 0) ||
                            'At least one option must be selected!',
                        ]"
                      >
                      </v-checkbox>
                    </v-container>

                    <!-- Dropdown Section -->
                    <v-container v-else-if="step.type === 'dropdown'" fluid>
                      <v-select
                        class="option-text"
                        label="Select an option"
                        dense
                        v-model="
                          questionnaireFormData[step.section_name][step.key]
                        "
                        :items="
                          Object.entries(step.options).map(([key, value]) => ({
                            text: value,
                            value: key,
                          }))
                        "
                        item-text="text"
                        item-value="value"
                        :rules="[
                          (v) => !!v || 'At least one option must be selected!',
                        ]"
                      ></v-select>
                    </v-container>
                  </div>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-form>
        </v-card-text>
        <div
          v-if="isShowTasteProfileSectionMessage"
          class="taste-profile-start-content"
        >
          <p>From now taste profile questions starts</p>
          <!-- <v-btn class="continue-btn" @click="removeShutterEffect">
            Continue
          </v-btn> -->
        </div>

        <v-divider></v-divider>

        <v-card-actions class="justify-end">
          <v-btn
            @click="prevStep"
            color="blue"
            dark
            :disabled="currentStep === 0 || currentStep === steps.length"
            :small="$vuetify?.breakpoint?.smAndDown"
            >Back</v-btn
          >
          <v-btn
            :class="[{ 'd-none': isShowTasteProfileSectionMessage }]"
            @click="nextStep"
            color="blue"
            dark
            v-if="currentStep < steps.length - 1"
            :small="$vuetify?.breakpoint?.smAndDown"
            >Next</v-btn
          >
          <v-btn
            @click="submit"
            color="Black lighten-1"
            dark
            v-if="currentStep === steps.length - 1"
            :small="$vuetify?.breakpoint?.smAndDown"
            >Submit</v-btn
          >
          <v-btn
            v-show="isShowTasteProfileSectionMessage"
            @click="removeShutterEffect"
            color="blue"
            dark
            :small="$vuetify?.breakpoint?.smAndDown"
            >Next</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div v-if="isGotRecommendedProducts">
      <v-container>
        <v-row class="d-flex justify-center">
          <v-col cols="12" sm="10" md="8" lg="8">
            <div
              :class="[
                $vuetify.breakpoint.xs ? 'text-body-2' : 'text-subtitle-1',
              ]"
            >
              <div>
                <p class="mt-3 mt-md-4 font-weight-bold">あなたのタイプ</p>
              </div>
              <div>
                <p>{{ tasteProfileDetails.name }}</p>
                <v-img
                  :src="getImagePath(tasteProfileDetails.name)"
                  :alt="getImagePath(tasteProfileDetails.name)"
                  height="20rem"
                  contain
                ></v-img>
              </div>

              <div class="mb-2">
                <p class="mt-3 mt-md-4">
                  <span class="font-weight-bold"> パターンの特徴: </span>
                </p>
                <ul>
                  <li
                    v-for="(item, index) in tasteProfileDetails.characteristics"
                    :key="index"
                  >
                    {{ item }}
                  </li>
                </ul>
              </div>
              <!-- <div class="">
                <v-chip-group
                  v-for="(values, type) in testProfileTypes"
                  :key="type"
                  class="me-1 me-sm-2"
                  column
                  multiple
                >
                  <div class="d-flex flex-wrap alignment">
                    <p
                      v-if="type == 'tasteTypes' && values.length > 0"
                      :class="[$vuetify.breakpoint.xs ? 'mb-2' : 'mb-0']"
                      class="me-4 font-weight-bold"
                    >
                      お薦めの日本酒の味:
                    </p>
                    <p
                      v-if="
                        type == 'manufacturingProcesses' && values.length > 0
                      "
                      :class="[$vuetify.breakpoint.xs ? 'mb-2' : 'mb-0']"
                      class="me-4 font-weight-bold"
                    >
                      お薦めの日本酒の種類:
                    </p>
                    <v-chip
                      v-for="value in values"
                      :key="value"
                      outlined
                      small
                      :class="[$vuetify.breakpoint.xs ? 'small' : '']"
                    >
                      {{ value }}
                    </v-chip>
                  </div>
                </v-chip-group>
              </div> -->
              <div>
                <v-chip-group
                  v-for="(values, type) in testProfileTypes"
                  :key="type"
                  class="me-1 me-sm-2"
                  column
                  multiple
                >
                  <div class="d-flex flex-wrap alignment">
                    <p
                      v-if="type == 'tasteCategoryTypes' && values.length > 0"
                      :class="[$vuetify.breakpoint.xs ? 'mb-2' : 'mb-0']"
                      class="me-4 font-weight-bold"
                    >
                      お薦めの日本酒の味:
                    </p>
                    <p
                      v-if="
                        type == 'manufacturingCategoryTypes' &&
                        values.length > 0
                      "
                      :class="[$vuetify.breakpoint.xs ? 'mb-2' : 'mb-0']"
                      class="me-4 font-weight-bold"
                    >
                      お薦めの日本酒の種類:
                    </p>
                    <v-chip
                      v-for="value in values"
                      :key="value"
                      outlined
                      small
                      :class="[$vuetify.breakpoint.xs ? 'small' : '']"
                    >
                      {{ value }}
                    </v-chip>
                  </div>
                </v-chip-group>
              </div>
              <div>
                <p class="mt-3 mt-md-4">
                  <span class="font-weight-bold">説明: </span>
                  {{ tasteProfileDetails.explanation }}
                </p>
                <v-divider class="mt-3"></v-divider>
                <template>
                  <v-container>
                    <v-row class="d-flex justify-end">
                      <v-dialog
                        v-model="showFeedbackTextArea"
                        max-width="600px"
                      >
                        <v-card>
                          <v-card-title>
                            <div>
                              <v-icon v-if="thumbsUp"
                                >mdi-thumb-up-outline</v-icon
                              >
                              <v-icon v-else>mdi-thumb-down-outline</v-icon>
                            </div>
                            Add additional feedback
                            <v-spacer></v-spacer>
                            <!-- <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                  </v-menu> -->
                          </v-card-title>
                          <v-card-text class="pb-0">
                            <v-textarea
                              v-model="feedbackText"
                              name="positive feedback"
                              outlined
                              auto-grow
                              :placeholder="
                                thumbsUp
                                  ? 'What do you like about the response?'
                                  : 'What can we improve in our response?'
                              "
                            ></v-textarea>
                          </v-card-text>
                          <v-card-actions class="py-4">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="proceedWithFeedbackText">
                              Submit Feedback
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-btn
                        color="blue lighten-2"
                        class="ma-2"
                        dark
                        icon
                        @click="handleThumbsUp"
                      >
                        <v-icon>{{
                          thumbsUp ? "mdi-thumb-up" : "mdi-thumb-up-outline"
                        }}</v-icon>
                      </v-btn>
                      <v-btn
                        color="blue lighten-2"
                        class="ma-2"
                        dark
                        icon
                        @click="handleThumbsDown"
                      >
                        <v-icon>{{
                          thumbsDown
                            ? "mdi-thumb-down"
                            : "mdi-thumb-down-outline"
                        }}</v-icon>
                      </v-btn>
                    </v-row>
                  </v-container>
                </template>
              </div>
            </div>
          </v-col>
        </v-row>

        <div
          v-for="(allProducts, category) in recommendedProducts"
          :key="category"
        >
          <div class="text-h6 my-4 text-center">
            {{ recommendedProducts[category].length > 0 ? category : "" }}
          </div>
          <v-row class="d-flex justify-center">
            <v-col
              v-for="product in allProducts"
              :key="product?.item_id"
              cols="12"
              sm="10"
              md="8"
              lg="8"
            >
              <ProductCard
                :id="product?.item_id"
                :name="product?.title"
                :image="product?.image_url"
                :price="product?.price"
                :description="product?.description"
                :tasteType="[product?.flavour_type.join('・')]"
                :ingredientType="[product?.variant.join('・')]"
                :recommendedWayToDrink="product?.recommended_way_to_consume"
                :recommendedJapaneseFood="product?.japanese_food"
                :recommendedWesternFood="product?.western_food"
                :recommendedChineseAndEthnicFood="
                  product?.chinese_and_ethnic_food
                "
                :recommendedSweetsAndFruit="product?.sweets_and_fruit"
              />
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import ProductCard from "@/components/ProductCard.vue";
import CredentialsForm from "@/components/CredentialsForm.vue";
import CircularLoader from "@/components/CircularLoader.vue";
import SnackbarNotifier from "@/components/snackbar/SnackbarNotifier.vue";
import { SNACKBAR_NOTIFIER } from "@/store/action_types";
import {
  dummyFullRecommendations,
  dummyPartialRecommendations,
} from "@/helpers/sample_recommendations";

export default {
  name: "QuestionnairePage",
  components: {
    CircularLoader,
    ProductCard,
    CredentialsForm,
    SnackbarNotifier,
  },
  data() {
    return {
      dummyFullRecommendations,
      dummyPartialRecommendations,
      isCredentialsFormValid: "",
      requiredKeys: {
        item_id: "",
        title: "",
        image_url: "",
        price: "",
        description: "",
        flavour_type: "",
        variant: "",
        // sweetness_dryness: "",
        // intensity_type: "",
        // ingredient_type: "",
        // manufacturing_type: "",
        recommended_way_to_consume: "",
        japanese_food: "",
        western_food: "",
        chinese_and_ethnic_food: "",
        sweets_and_fruit: "",
      },
      isLoading: false,
      isOpenQuestionnaireDialog: false,
      currentStep: 0,
      questionnaireFormData: {},
      steps: [],
      displayProgress: 0,
      isStepValid: false,
      isStepperFormValid: true,
      questionnaireName: "",
      isGotRecommendedProducts: false,
      recommendedProducts: {},
      testProfileTypes: {},
      tasteProfileDetails: {},
      profileQuestionnaire: {},
      thumbsUp: false,
      thumbsDown: false,
      showFeedbackTextArea: false,
      feedbackText: "",
      classDetails: {
        A_1: {
          class: "A_1",
          name: "甘舌-A",
          characteristics: [
            "甘味を好み、苦味と酸味はやや苦手とする",
            "ピュアな味覚を持ったタイプ",
          ],
          sake_flavors: ["中口", "やや甘口", "甘口"],
          sake_types: [
            "純米酒",
            "純米吟醸酒",
            "純米大吟醸酒",
            "本醸造酒",
            "純米原酒",
          ],
          recommended_dishes_and_snacks: [
            "すき焼き",
            "牛すじ煮込み",
            "だし巻き卵",
            "焼き鳥（たれ）",
            "大学いも",
          ],
        },
        A_2: {
          class: "A_2",
          name: "甘舌-B",
          characteristics: [
            "全体的にバランスがよく、薄味を好む",
            "未体験の味にも挑戦するタイプ",
          ],
          sake_flavors: ["中口", "やや甘口", "甘口"],
          sake_types: [
            "純米酒",
            "純米吟醸酒",
            "純米大吟醸酒",
            "本醸造酒",
            "純米原酒",
          ],
          recommended_dishes_and_snacks: [
            "すき焼き",
            "牛すじ煮込み",
            "だし巻き卵",
            "焼き鳥（たれ）",
            "大学いも",
          ],
        },
        A_3: {
          class: "A_3",
          name: "甘舌-C",
          characteristics: [
            "甘味とうま味を好み、刺激が強いものは好まない",
            "食の欲求に素直なタイプ",
          ],
          sake_flavors: ["中口", "やや甘口", "甘口"],
          sake_types: [
            "純米酒",
            "純米吟醸酒",
            "純米大吟醸酒",
            "本醸造酒",
            "純米原酒",
          ],
          recommended_dishes_and_snacks: [
            "すき焼き",
            "牛すじ煮込み",
            "だし巻き卵",
            "焼き鳥（たれ）",
            "大学いも",
          ],
        },
        A_4: {
          class: "A_4",
          name: "甘舌-D",
          characteristics: [
            "甘味とうま味を好む",
            "刺激が強いものは好まない",
            "好き嫌いがはっきりしている",
          ],
          sake_flavors: ["中口", "やや甘口", "甘口"],
          sake_types: [
            "純米酒",
            "純米吟醸酒",
            "純米大吟醸酒",
            "本醸造酒",
            "純米原酒",
          ],
          recommended_dishes_and_snacks: [
            "すき焼き",
            "牛すじ煮込み",
            "だし巻き卵",
            "焼き鳥（たれ）",
            "大学いも",
          ],
        },
        U_1: {
          class: "U_1",
          name: "うま舌-A",
          characteristics: [
            "薄味で、うま味と苦味を好む",
            "素材の味わいを重視するタイプ",
          ],
          sake_flavors: ["薄やや辛口", "中口", "やや甘口"],
          sake_types: [
            "純米酒",
            "純米吟醸酒",
            "純米大吟醸酒",
            "本醸造酒",
            "純米原酒",
            "純米大吟醸原酒",
          ],
          recommended_dishes_and_snacks: [
            "肉じゃが",
            "おでん",
            "あさりバター",
            "茶わん蒸し",
            "ほうれん草のお浸し",
          ],
        },
        U_2: {
          class: "U_2",
          name: "うま舌-B",
          characteristics: [
            "薄味でうま味を好む",
            "しょっぱいものや苦味はやや苦手",
          ],
          sake_flavors: ["やや辛口", "中口", "やや甘口"],
          sake_types: [
            "純米酒",
            "純米吟醸酒",
            "純米大吟醸酒",
            "本醸造酒",
            "純米原酒",
            "純米大吟醸原酒",
          ],
          recommended_dishes_and_snacks: [
            "肉じゃが",
            "おでん",
            "あさりバター",
            "茶わん蒸し",
            "ほうれん草のお浸し",
          ],
        },
        U_3: {
          class: "U_3",
          name: "うま舌-C",
          characteristics: [
            "薄味で酸味のある味を好む",
            "苦味に敏感でやや苦手とし、繊細な味覚を持つタイプ",
          ],
          sake_flavors: ["やや辛口", "中口", "やや甘口"],
          sake_types: [
            "純米酒",
            "純米吟醸酒",
            "純米大吟醸酒",
            "本醸造酒",
            "純米原酒",
            "純米大吟醸原酒",
          ],
          recommended_dishes_and_snacks: [
            "肉じゃが",
            "おでん",
            "あさりバター",
            "茶わん蒸し",
            "ほうれん草のお浸し",
          ],
        },
        U_4: {
          class: "U_4",
          name: "うま舌-D",
          characteristics: [
            "薄味で、うま味があるものを好む",
            "すっきりした柑橘系の酸味が好きなタイプ",
          ],
          sake_flavors: ["薄やや辛口", "中口", "やや甘口"],
          sake_types: [
            "純米酒",
            "純米吟醸酒",
            "純米大吟醸酒",
            "本醸造酒",
            "純米原酒",
            "純米大吟醸原酒",
          ],
          recommended_dishes_and_snacks: [
            "肉じゃが",
            "おでん",
            "あさりバター",
            "茶わん蒸し",
            "ほうれん草のお浸し",
          ],
        },
        C_1: {
          class: "C_1",
          name: "こども舌-A",
          characteristics: ["バランスが取れている", "やや苦味が苦手"],
          sake_flavors: ["中口", "やや甘口", "甘口"],
          sake_types: ["純米酒", "純米吟醸酒", "純米大吟醸酒"],
          recommended_dishes_and_snacks: [
            "ハンバーグ",
            "肉団子",
            "コロッケ",
            "ポテトサラダ",
            "枝豆",
          ],
        },
        C_2: {
          class: "C_2",
          name: "こども舌-B",
          characteristics: [
            "甘味とうま味を好み苦味が苦手",
            "好き嫌いがはっきりしている",
          ],
          sake_flavors: ["中口", "やや甘口", "甘口"],
          sake_types: ["純米酒", "純米吟醸酒", "純米大吟醸酒"],
          recommended_dishes_and_snacks: [
            "ハンバーグ",
            "肉団子",
            "コロッケ",
            "ポテトサラダ",
            "枝豆",
          ],
        },
        C_3: {
          class: "C_3",
          name: "こども舌-C",
          characteristics: [
            "塩味と甘味があるしっかりとした味を好む",
            "苦味が苦手で酸味もやや苦手",
          ],
          sake_flavors: ["中口", "やや甘口", "甘口"],
          sake_types: ["純米酒", "純米吟醸酒", "純米大吟醸酒"],
          recommended_dishes_and_snacks: [
            "ハンバーグ",
            "肉団子",
            "コロッケ",
            "ポテトサラダ",
            "枝豆",
          ],
        },
        B_1: {
          class: "B_1",
          name: "バランス舌-A",
          characteristics: [
            "日本人の平均的な食卓の味わいを好む",
            "好き嫌いが少ない",
          ],
          sake_flavors: ["やや辛口", "中口", "やや甘口"],
          sake_types: ["純米酒", "純米大吟醸酒", "純米吟醸酒"],
          recommended_dishes_and_snacks: [
            "ローストビーフ",
            "鰹のたたき",
            "ポテトサラダ",
            "卯の花",
            "切り干し大根",
          ],
        },
        B_2: {
          class: "B_2",
          name: "バランス舌-B",
          characteristics: [
            "うま味の許容が少しだけ低い",
            "素材を活かした味を好むタイプ",
          ],
          sake_flavors: ["やや辛口", "中口", "やや甘口"],
          sake_types: ["純米酒", "純米大吟醸酒", "純米吟醸酒"],
          recommended_dishes_and_snacks: [
            "ローストビーフ",
            "鰹のたたき",
            "ポテトサラダ",
            "卯の花",
            "切り干し大根",
          ],
        },
        B_3: {
          class: "B_3",
          name: "バランス舌-C",
          characteristics: [
            "苦味、酸味、うま味のある複雑な味を好む",
            "塩分にも敏感で、甘みもほどほどの大人の嗜好を持つタイプ",
          ],
          sake_flavors: ["やや辛口", "中口", "やや甘口"],
          sake_types: ["純米酒", "純米大吟醸酒", "純米吟醸酒"],
          recommended_dishes_and_snacks: [
            "ローストビーフ",
            "鰹のたたき",
            "ポテトサラダ",
            "卯の花",
            "切り干し大根",
          ],
        },
        S_1: {
          class: "S_1",
          name: "とんがり舌-A",
          characteristics: [
            "塩味と酸味の強い味を好む",
            "味わいのメリハリがしっかりしたものを好むタイプ",
          ],
          sake_flavors: ["辛口", "やや辛口", "中口"],
          sake_types: ["純米酒", "純米吟醸酒", "本醸造酒", "純米原酒"],
          recommended_dishes_and_snacks: [
            "アジの南蛮漬け",
            "焼き鳥（塩）",
            "餃子",
            "ニラ玉",
            "いかの塩辛",
          ],
        },
        S_2: {
          class: "S_2",
          name: "とんがり舌-B",
          characteristics: [
            "塩味と酸味の強いはっきり味を好む",
            "苦いものやうま味が強いものはあまり得意ではない",
          ],
          sake_flavors: ["辛口", "やや辛口", "中口", "やや甘口"],
          sake_types: ["純米酒", "純米吟醸酒", "本醸造酒", "純米原酒"],
          recommended_dishes_and_snacks: [
            "アジの南蛮漬け",
            "焼き鳥（塩）",
            "餃子",
            "ニラ玉",
            "いかの塩辛",
          ],
        },
        S_3: {
          class: "S_3",
          name: "とんがり舌-C",
          characteristics: [
            "塩気と酸味の強いしっかりした味わいを好む",
            "苦いものはあまり得意ではない",
          ],
          sake_flavors: ["辛口", "やや辛口", "中口", "やや甘口"],
          sake_types: ["純米酒", "純米吟醸酒", "本醸造酒", "純米原酒"],
          recommended_dishes_and_snacks: [
            "アジの南蛮漬け",
            "焼き鳥（塩）",
            "餃子",
            "ニラ玉",
            "いかの塩辛",
          ],
        },
        N_1: {
          class: "N_1",
          name: "おやじ舌-A",
          characteristics: [
            "苦い味つけの食事も得意",
            "全体的にしっかりとした味付けを好む",
          ],
          sake_flavors: ["辛口", "やや辛口", "中口"],
          sake_types: [
            "純米酒",
            "純米吟醸酒",
            "吟醸酒",
            "本醸造酒",
            "酒粕焼酎",
          ],
          recommended_dishes_and_snacks: [
            "もつ煮",
            "きんぴらごぼう",
            "麻婆豆腐",
            "ビーフシチュー",
            "焼ビーフン炒め",
          ],
        },
        N_2: {
          class: "N_2",
          name: "おやじ舌-B",
          characteristics: [
            "苦味と塩味が強い濃い味付けを好む",
            "お酒や味覚体験が豊富",
          ],
          sake_flavors: ["辛口", "やや辛口", "中口"],
          sake_types: [
            "純米酒",
            "純米吟醸酒",
            "吟醸酒",
            "本醸造酒",
            "酒粕焼酎",
          ],
          recommended_dishes_and_snacks: [
            "もつ煮",
            "きんぴらごぼう",
            "麻婆豆腐",
            "ビーフシチュー",
            "焼ビーフン炒め",
          ],
        },
        N_3: {
          class: "N_3",
          name: "おやじ舌-C",
          characteristics: [
            "苦味と酸味が強く刺激的な味付けを好む",
            "食経験が豊か",
          ],
          sake_flavors: ["辛口", "やや辛口", "中口"],
          sake_types: [
            "純米酒",
            "純米吟醸酒",
            "吟醸酒",
            "本醸造酒",
            "酒粕焼酎",
          ],
          recommended_dishes_and_snacks: [
            "もつ煮",
            "きんぴらごぼう",
            "麻婆豆腐",
            "ビーフシチュー",
            "焼ビーフン炒め",
          ],
        },
        N_4: {
          class: "N_4",
          name: "おやじ舌-D",
          characteristics: [
            "苦味、酸味、塩味が強い濃い味付けを好む",
            "生活習慣病には要注意",
          ],
          sake_flavors: ["辛口", "やや辛口", "中口"],
          sake_types: [
            "純米酒",
            "純米吟醸酒",
            "吟醸酒",
            "本醸造酒",
            "酒粕焼酎",
          ],
          recommended_dishes_and_snacks: [
            "もつ煮",
            "きんぴらごぼう",
            "麻婆豆腐",
            "ビーフシチュー",
            "焼ビーフン炒め",
          ],
        },
        N_5: {
          class: "N_5",
          name: "おやじ舌-E",
          characteristics: [
            "塩気の強い濃い味付けを好む",
            "塩分摂取量には要注意",
          ],
          sake_flavors: ["辛口", "やや辛口", "中口"],
          sake_types: [
            "純米酒",
            "純米吟醸酒",
            "吟醸酒",
            "本醸造酒",
            "酒粕焼酎",
          ],
          recommended_dishes_and_snacks: [
            "もつ煮",
            "きんぴらごぼう",
            "麻婆豆腐",
            "ビーフシチュー",
            "焼ビーフン炒め",
          ],
        },
      },
      isShowTasteProfileSectionMessage: false,
    };
  },
  computed: {
    ...mapGetters("credentials_form", [
      "isOpenCredentialsForm",
      "credentialsFromData",
      "isValidCredentialsForm",
    ]),

    progressCalculate() {
      if (this.steps.length === 0) {
        return 0;
      }
      let calculate = (this.currentStep / this.steps.length) * 100;
      return calculate;
    },
  },
  methods: {
    ...mapActions("snackbar_notifier", [SNACKBAR_NOTIFIER]),

    async nextStep() {
      const isValid = await this.$refs.stepperForm.validate();
      // if (
      //   this.isShowTasteProfileSectionMessage === true &&
      //   this.currentStep === 20
      // ) {
      //   this.showSnackbarNotifier(
      //     "Please press the continue button to proceed!",
      //     "warning",
      //     2000
      //   );
      //   return;
      // }
      if (!isValid) {
        return;
      }

      if (this.currentStep < this.steps.length - 1) {
        this.currentStep++;
      }

      console.log("this.questionnaireFormData:\n", this.questionnaireFormData);
    },
    async prevStep() {
      if (this.currentStep > 0) {
        if (this.currentStep == 20) {
          this.isShowTasteProfileSectionMessage = false;
        }
        this.currentStep--;
      }
    },
    async removeShutterEffect() {
      this.isShowTasteProfileSectionMessage = false;
    },
    getImagePath(imageName) {
      const path = require(`@/assets/pattern_images/${imageName}.jpg`);
      console.log("Resolved Path:", path);
      return path;
    },
    // processTasteProfile(data) {
    //   console.log("processTasteProfile:", data);
    //   const tasteTypes = new Set();
    //   const ingredientTypes = new Set();

    //   Object.values(data).forEach((products) =>
    //     products.forEach((product) => {
    //       // (product.sweetness_dryness || []).forEach((type) =>
    //       //   tasteTypes.add(type)
    //       // );
    //       // (product.ingredient_type || []).forEach((type) =>
    //       //   ingredientTypes.add(type)
    //       // );
    //       const sweetnessDryness = product.sweetness_dryness?.join("・") || "";
    //       const intensityType = product.intensity_type?.join("・") || "";

    //       // Combine sweetness_dryness and intensity_type with "・"
    //       const combinedTaste = [sweetnessDryness, intensityType]
    //         .filter((part) => part) // Remove empty parts
    //         .join("・");
    //       if (combinedTaste) {
    //         tasteTypes.add(combinedTaste);
    //       }
    //       (product.ingredient_type || []).forEach((type) =>
    //         ingredientTypes.add(type)
    //       );
    //     })
    //   );
    //   console.log("tasteTypes --> ", { tasteTypes });
    //   console.log("ingredientTypes --> ", { ingredientTypes });
    //   return {
    //     tasteTypes: [...tasteTypes],
    //     ingredientTypes: [...ingredientTypes],
    //   };
    // },

    processTasteProfile(data) {
      console.log("processTasteProfile:", data);

      const tasteTypes = new Set();
      const manufacturingProcesses = new Set();

      Object.values(data)
        .flat()
        .forEach((product) => {
          if (product.flavour_type?.length > 0) {
            tasteTypes.add(product.flavour_type.join("・"));
          }
          if (product.variant?.length > 0) {
            manufacturingProcesses.add(product.variant.join("・"));
          }
        });

      console.log("tasteCategoryTypes:", Array.from(tasteTypes));
      console.log(
        "manufacturingCategoryTypes:",
        Array.from(manufacturingProcesses)
      );

      return {
        tasteCategoryTypes: Array.from(tasteTypes),
        manufacturingCategoryTypes: Array.from(manufacturingProcesses),
      };
    },

    async extractingGptResponse(gptResponse) {
      console.log("gptResponse:", gptResponse);
      let responseString = gptResponse?.detail?.response;

      // Check and clean the response string
      if (responseString && responseString.startsWith("```")) {
        responseString = responseString
          .replace(/^```(json)?\n/, "") // Remove starting markers
          .replace(/\n```$/, ""); // Remove trailing markers
      }

      // Log the cleaned string to check if it's valid JSON
      console.log("Cleaned response string:", responseString);

      try {
        const sanitizedString = responseString.replace(/'/g, '"');
        const responseObject = JSON.parse(sanitizedString); // Try parsing
        console.log("Parsed response:", responseObject); // Log the parsed object

        const className = this.classDetails[responseObject?.result]?.name;
        const characteristicsList =
          this.classDetails[responseObject.result]?.characteristics;
        // const characteristicsList = characteristicsText.split("\n");
        const categoriesList = responseObject?.categories
          ? responseObject?.categories.split(",")
          : [];

        return {
          conversationId: gptResponse?.detail?.conversation_id,
          result: responseObject.result,
          name: className,
          characteristics: characteristicsList,
          explanation: responseObject.explanation,
          preferredCategories: categoriesList,
        };
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    },
    async processingRecommendations(recommendations) {
      const processedRecommendations = {};

      Object.entries(recommendations).forEach(([category, products]) => {
        processedRecommendations[category] = products.map((product) => ({
          item_id: product[this.requiredKeys.item_id],
          title: product[this.requiredKeys.title],
          image_url: product[this.requiredKeys.image_url],
          price: product[this.requiredKeys.price],
          description: product[this.requiredKeys.description],
          flavour_type: product[this.requiredKeys.flavour_type],
          variant: product[this.requiredKeys.variant],
          /*  sweetness_dryness: product[this.requiredKeys.sweetness_dryness],
          intensity_type: product[this.requiredKeys.intensity_type],
          ingredient_type: product[this.requiredKeys.ingredient_type],
          manufacturing_type: product[this.requiredKeys.manufacturing_type], */
          recommended_way_to_consume:
            product[this.requiredKeys.recommended_way_to_consume],
          japanese_food: product[this.requiredKeys.japanese_food],
          western_food: product[this.requiredKeys.western_food],
          chinese_and_ethnic_food:
            product[this.requiredKeys.chinese_and_ethnic_food],
          sweets_and_fruit: product[this.requiredKeys.sweets_and_fruit],
          score: product["score"],
        }));
      });

      return processedRecommendations;
    },

    async selectingTop3Categories(
      processedRecommendations,
      preferredTopCategories
    ) {
      console.log("preferredTopCategories: ", preferredTopCategories);
      let top3Categories = [];
      // Step 1: Filter categories with items
      const categoriesWithItems = Object.entries(
        processedRecommendations
      ).reduce((result, [key, value]) => {
        if (value.length > 0) result[key] = value;
        return result;
      }, {});
      console.log("categoriesWithItems: ", categoriesWithItems);

      // Step 2: Find max score for each category
      const categoriesMaxScore = Object.entries(categoriesWithItems).map(
        ([category, items]) => {
          const maxScore = Math.max(...items.map((item) => item.score));
          return { category, maxScore };
        }
      );
      console.log("categoriesMaxScore: ", categoriesMaxScore);

      // Step 3: Sort categories by max score (descending order)
      categoriesMaxScore.sort((a, b) => b.maxScore - a.maxScore);

      /*       // Select the top 3 categories based on the max score
      const top3MaxScoreCategories = categoriesMaxScore
        .slice(0, 3)
        .map((item) => item.category);
      console.log("top3Categories: ", top3MaxScoreCategories); */

      // Step 4: Cross-check preferredTopCategories and categoriesWithItems
      const matchedPreferredTopCategories = preferredTopCategories.filter(
        (category) => categoriesWithItems[category]
      );
      console.log(
        "matchedPreferredTopCategories: ",
        matchedPreferredTopCategories
      );

      // Adding preferred categories to the top of the list
      top3Categories.push(...matchedPreferredTopCategories);
      top3Categories.push(
        ...categoriesMaxScore
          .filter(
            ({ category }) => !matchedPreferredTopCategories.includes(category)
          )
          .slice(0, 3 - matchedPreferredTopCategories.length) // Ensure we only add enough to make 3 categories
          .map(({ category }) => category)
      );
      console.log("Top 3 Categories: ", top3Categories);
      return top3Categories;
    },

    separateSurveyData(data) {
      console.log("separateSurveyData: ", data);
      const sectionNames = Object.keys(data);
      const recommendationData = data[sectionNames[0]];

      const mappingForRecommendation = {
        q1: "q1",
        q2: "q2",
        q3: "q3",
        q4: "q4",
        q5: "q5",
        q6: "q6",
        q7: "q7",
        q8: "q8",
        q9: "q9",
        q10: "q10",
        q11: "q11",
        q12: "q12",
        q13: "q13",
        q14: "q14",
        q15: "q15",
      };

      const mappingForAskTasteCategory = {
        q1: "q1",
        q2: "q2",
        q3: "q3",
        q4: "q4",
        q5: "q5",
        q6: "q6",
        q7: "q7",
        q8: "q8",
        q9: "q9",
        q10: "q10",
        q11: "q11",
        q12: "q12",
        q13: "q13",
        q14: "q14",
        q15: "q15",
      };

      // Create the recommendation profile
      let recommendationProfileAnswers = {};
      for (const [oldKey, newKey] of Object.entries(mappingForRecommendation)) {
        if (recommendationData[oldKey] !== undefined) {
          recommendationProfileAnswers[newKey] = recommendationData[oldKey];
        }
      }

      // Create the taste category profile
      let askTasteCategoryProfileAnswers = {};
      for (const [oldKey, newKey] of Object.entries(
        mappingForAskTasteCategory
      )) {
        if (recommendationData[oldKey] !== undefined) {
          askTasteCategoryProfileAnswers[newKey] = recommendationData[oldKey];
        }
      }

      console.log(
        "recommendationProfileAnswers: ",
        recommendationProfileAnswers
      );
      console.log(
        "askTasteCategoryProfileAnswers: ",
        askTasteCategoryProfileAnswers
      );

      // Return the structure with the dynamic key, maintaining the correct format
      return {
        recommendationBody: {
          [sectionNames[0]]: recommendationProfileAnswers,
        },
        tasteCategoryBody: askTasteCategoryProfileAnswers,
      };
    },

    generateQuery(answers) {
      const questions = this.profileQuestionnaire.questions.profile_questions;

      // Helper function to get the answer text
      const getAnswerText = (questionKey, answerKey) => {
        const options = questions[questionKey]?.options || {};
        if (Array.isArray(answerKey)) {
          return answerKey.map((key) => options[key]).join(", ");
        }
        return options[answerKey] || "";
      };

      // Template rendering
      const renderQuestion = (index, questionText, answerText) =>
        `Q${index}: ${questionText}\nA${index}: ${answerText}`;

      // Build the query
      const query = Object.keys(questions)
        .map((key, index) => {
          const questionText = questions[key]?.question || "";
          const answerText = getAnswerText(key, answers[key]) || "";
          return renderQuestion(index + 1, questionText, answerText);
        })
        .join("\n\n");

      return query.trim();
    },

    submit() {
      const isValid = this.$refs.stepperForm.validate();
      if (!isValid) {
        return;
      }
      this.currentStep++;
      setTimeout(() => {
        this.isOpenQuestionnaireDialog = false;
        console.log("My Questionnaire Answers:\n", this.questionnaireFormData); // working
      }, 500);

      this.isLoading = true;

      const headers = {
        accept: "application/json",
        "Content-Type": "application/json",
        "project-key": this.credentialsFromData["projectKey"],
        "api-key": this.credentialsFromData["apiKey"],
      };
      const surveyResults = this.separateSurveyData(this.questionnaireFormData);
      console.log(
        "recommendationBody:",
        JSON.stringify(surveyResults.recommendationBody, null, 2)
      );
      console.log(
        "tasteCategoryBody:",
        JSON.stringify(surveyResults.tasteCategoryBody, null, 2)
      );
      const formattedQuery = this.generateQuery(
        surveyResults.tasteCategoryBody
      );
      console.log("Formated Question & Answers:\n", formattedQuery);
      console.log("Stringify Query:", JSON.stringify(formattedQuery));
      const {
        item_id,
        title,
        image_url,
        price,
        description,
        flavour_type,
        variant,
        // sweetness_dryness,
        // intensity_type,
        // ingredient_type,
        // manufacturing_type,
        recommended_way_to_consume,
        japanese_food,
        western_food,
        chinese_and_ethnic_food,
        sweets_and_fruit,
      } = this.requiredKeys;
      console.log("flavour_type: ", flavour_type);
      const resultIncludedColumns =
      flavour_type === "N/A"
          ? [item_id, title, image_url, price, description]
          : [
              item_id,
              title,
              image_url,
              price,
              description,
              flavour_type,
              variant,
              /*            sweetness_dryness,
              intensity_type,
              ingredient_type,
              manufacturing_type, */
              recommended_way_to_consume,
              japanese_food,
              western_food,
              chinese_and_ethnic_food,
              sweets_and_fruit,
            ];
      const requestBody = {
        user_id: "a0cc6beb-2909-459b-be55-62196af78ce4",
        member_id: "df3456tg-2909-459b-be55-62196afedf85",
        questionnaire_id: this.credentialsFromData["questionnaireId"],
        values: surveyResults.recommendationBody,
        recommend_count: this.credentialsFromData["recommendationCount"],
        result_includes: resultIncludedColumns, // result_includes: ["JANコード", "商品名", "images", "参考価格", "商品説明(60文字)", "タイプ", "種類", "甘辛区分", "濃淡区分", "特定名称","製造区分", "飲み頃温度", "和食", "洋食", "中華・エスニック", "スイーツ・フルーツ" ],
        top_k: 20,
        is_keyword_enabled: false,
      };
      axios
        .post("https://api.recommender.gigalogy.com/v1/questionnaire/recommend", requestBody, {
          headers,
        })
        .then((response) => {
          if (response.data.request_id) {
            console.log("Recommendations response:\n", response.data);
            const recommendations = response.data?.detail?.recommendations;
            // const recommendations = dummyFullRecommendations?.detail?.recommendations;
            // const recommendations = dummyPartialRecommendations?.detail?.recommendations;

            let getProcessedRecommendation =
              this.processingRecommendations(recommendations);

            console.log(
              "getProcessedRecommendation",
              getProcessedRecommendation
            );

            // Production GPT ask request body
            const gptRequestBody = {
              user_id: "a0cc6beb-2909-459b-be55-62196af78ce4",
              member_id: "df3456tg-2909-459b-be55-62196afedf85",
              query: JSON.stringify(formattedQuery),
              conversation_type: "question",
              gpt_profile_id: "b753ea26-e310-4079-8d1b-e8af3c7cee23",
              context_preference: {
                preferred: {
                  dataset_id: "7bfe2590-a096-4235-afa8-f83fccd18186",
                },
              },
            };

            // Local GPT ask request body
            /* const gptRequestBody = {
              user_id: "a0cc6beb-2909-459b-be55-62196af78ce4",
              member_id: "df3456tg-2909-459b-be55-62196afedf85",
              query: JSON.stringify(formattedQuery),
              conversation_type: "question",
              gpt_profile_id: "d277daec-6388-42f1-8f52-b08370c19a44",
              context_preference: {
                preferred: {
                  dataset_id: "8fb13ed0-45aa-4596-9de6-702703a289e1",
                },
              },
            }; */

            return Promise.all([
              getProcessedRecommendation,
              axios.post("https://api.recommender.gigalogy.com/v1/gpt/ask", gptRequestBody, {
                headers,
              }),
            ]);
          }
        })
        .then(async ([getProcessedRecommendation, gptResponse]) => {
          if (gptResponse) {
            console.log(
              "Received getProcessedRecommendation:",
              getProcessedRecommendation
            );
            console.log("GPT response:", gptResponse.data);

            const {
              conversationId,
              result,
              name,
              characteristics,
              explanation,
              preferredCategories,
            } = await this.extractingGptResponse(gptResponse.data);

            this.tasteProfileDetails = {
              conversationId,
              result,
              name,
              characteristics,
              explanation,
              preferredCategories,
            };
            console.log("tasteProfileDetails", this.tasteProfileDetails);

            const processedTop3CategoriesRecommendations = {};

            let getTop3Categories = await this.selectingTop3Categories(
              getProcessedRecommendation,
              preferredCategories
            );

            getTop3Categories.forEach((category) => {
              processedTop3CategoriesRecommendations[category] =
                getProcessedRecommendation[category];
            });

            this.testProfileTypes = this.processTasteProfile(
              processedTop3CategoriesRecommendations
            );

            this.recommendedProducts = processedTop3CategoriesRecommendations;
          }

          this.isGotRecommendedProducts = true;
        })
        .catch((error) => {
          console.error("There was an error submitting the data!", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    showSnackbarNotifier(message, color, duration) {
      this.SNACKBAR_NOTIFIER({
        data: {
          isShow: true,
          message: message,
          color: color,
          duration: duration,
        },
      });
    },

    async getStartedWithQuestionnaire() {
      console.log(this.credentialsFromData, "Home page credentialsFromData");
      console.log(this.isValidCredentialsForm, "isValidCredentialsForm");

      // if (!this.isValidCredentialsForm) {
      //   this.showSnackbarNotifier(
      //     "Please fill in all required credentials before performing this action!",
      //     "warning",
      //     2000
      //   );
      //   return;
      // }
      const isAllFieldsEmpty =
        !this.credentialsFromData?.questionnaireId ||
        !this.credentialsFromData?.projectKey ||
        !this.credentialsFromData?.apiKey ||
        !this.credentialsFromData?.recommendationCount;

      if (isAllFieldsEmpty) {
        this.showSnackbarNotifier(
          "Please fill in all required credentials before performing this action!",
          "warning",
          2000
        );
        return;
      }
      this.currentStep = 0;
      this.isLoading = true;
      await this.getResultIncludesKeysByMapper();
      await this.fetchQuestionnaire();
    },

    fetchQuestionnaire() {
      // let Id = this.credentialsFromData["questionnaireId"];
      // const headers = {
      //   accept: "application/json",
      //   "project-key": this.credentialsFromData["projectKey"],
      //   "api-key": this.credentialsFromData["apiKey"],
      // };
      // axios
      //   .get(`https://api.recommender.gigalogy.com/v1/questionnaires/${Id}`, {
      //     headers,
      //   })
      axios
        .get("./data/questionnaire_v3.json")
        .then((response) => {
          console.log("Questionnaires Response:\n", response.data);
          this.profileQuestionnaire = response.data;
          const { name, questions } = response.data;
          this.steps = [];
          this.questionnaireName = name;

          Object.entries(questions).forEach(([section_name, questions_set]) => {
            if (!this.questionnaireFormData[section_name]) {
              console.log("inside", {
                ...this.questionnaireFormData[section_name],
              });
              this.$set(this.questionnaireFormData, section_name, {});
              // Process questions in each category_section
              Object.entries(questions_set).forEach(([key, value]) => {
                this.steps.push({ section_name, key, ...value });
                if (value.type === "checkbox") {
                  this.$set(this.questionnaireFormData[section_name], key, []);
                } else {
                  this.$set(this.questionnaireFormData[section_name], key, "");
                }
              });
            } else {
              // No need to reset the entire section, just ensure existing data is maintained
              Object.entries(questions_set).forEach(([key, value]) => {
                this.steps.push({ section_name, key, ...value });
                if (!(key in this.questionnaireFormData[section_name])) {
                  if (value.type === "checkbox") {
                    this.$set(
                      this.questionnaireFormData[section_name],
                      key,
                      []
                    );
                  } else {
                    this.$set(
                      this.questionnaireFormData[section_name],
                      key,
                      ""
                    );
                  }
                }
              });
              console.log("outside", {
                ...this.questionnaireFormData[section_name],
              });
            }
          });

          console.log("Steps setup complete: ", this.steps);
          console.log(
            "questionnaireFormData setup complete: ",
            this.questionnaireFormData
          );
        })
        .catch((error) => {
          console.error("There was an error fetching the data!", error);
        })
        .finally(() => {
          setTimeout(() => {
            this.isLoading = false; // Turn off loader
            this.isOpenQuestionnaireDialog = true; // Open Questionnaire Dialog after 1 seconds
          }, 1000);
        });
    },

    getResultIncludesKeysByMapper() {
      const headers = {
        accept: "application/json",
        "project-key": this.credentialsFromData["projectKey"],
        "api-key": this.credentialsFromData["apiKey"],
      };
      axios
        .get(`https://api.recommender.gigalogy.com/v1/mappers`, { headers })
        .then((response) => {
          console.log("Get Mapper Keys Response: \n", response.data);
          const responseDetails = response.data?.detail?.response;
          const { item_id, title, image_url, price, description, categories } =
            responseDetails;
          console.log("categories", categories);
          this.requiredKeys.item_id = item_id;
          this.requiredKeys.title = title;
          this.requiredKeys.image_url = image_url;
          this.requiredKeys.price = price;
          this.requiredKeys.description = description;
          this.requiredKeys.flavour_type =
            categories.find((column) => column.name === "タイプ")?.name ??
            "N/A";
          this.requiredKeys.variant =
            categories.find((column) => column.name === "種類")?.name ?? "N/A";
          /* this.requiredKeys.sweetness_dryness =
            categories.find((column) => column.name === "甘辛区分")?.name ??
            "N/A";
          this.requiredKeys.intensity_type =
            categories.find((column) => column.name === "濃淡区分")?.name ??
            "N/A";
          this.requiredKeys.ingredient_type =
            categories.find((column) => column.name === "特定名称")?.name ??
            "N/A";
          this.requiredKeys.manufacturing_type =
            categories.find((column) => column.name === "製造区分")?.name ??
            "N/A"; */
          this.requiredKeys.recommended_way_to_consume =
            categories.find((column) => column.name === "飲み頃温度")?.name ??
            "N/A";
          this.requiredKeys.japanese_food =
            categories.find((column) => column.name === "和食")?.name ?? "N/A";
          this.requiredKeys.western_food =
            categories.find((column) => column.name === "洋食")?.name ?? "N/A";
          this.requiredKeys.chinese_and_ethnic_food =
            categories.find((column) => column.name === "中華・エスニック")
              ?.name ?? "N/A";
          this.requiredKeys.sweets_and_fruit =
            categories.find((column) => column.name === "スイーツ・フルーツ")
              ?.name ?? "N/A";
        })
        .catch((error) => {
          console.error("There was an error fetching the data!", error);
        })
        .finally(() => {});
    },

    animateProgress(newValue) {
      const animationDuration = 500; // milliseconds
      const fps = 60;
      const frameDuration = 1000 / fps;
      const totalFrames = animationDuration / frameDuration;

      let currentFrame = 0;
      const startValue = this.displayProgress;
      const changeInValue = newValue - startValue;

      const animate = () => {
        currentFrame++;
        const progress = currentFrame / totalFrames;
        this.displayProgress =
          startValue + changeInValue * this.easeOutCubic(progress);

        if (currentFrame < totalFrames) {
          requestAnimationFrame(animate);
        }
      };

      requestAnimationFrame(animate);
    },
    easeOutCubic(t) {
      return 1 - Math.pow(1 - t, 3);
    },
    handleThumbsUp() {
      this.thumbsUp = true;
      this.thumbsDown = false;
      this.submitFeedback("positive");
      this.showFeedbackTextArea = true; // Show dialog for optional additional feedback
    },

    handleThumbsDown() {
      this.thumbsUp = false;
      this.thumbsDown = true;
      this.submitFeedback("negative");
      this.showFeedbackTextArea = true; // Show dialog for optional additional feedback
    },

    proceedWithFeedbackText() {
      let feedbackType = this.thumbsUp ? "positive" : "negative";
      if (this.feedbackText.trim()) {
        this.submitFeedback(feedbackType, this.feedbackText);
      }
      this.showFeedbackTextArea = false;
      this.feedbackText = "";
    },
    submitFeedback(feedbackType, message = null) {
      const conversationId = this.tasteProfileDetails.conversationId;
      const headers = {
        accept: "application/json",
        "project-key": this.credentialsFromData["projectKey"],
        "api-key": this.credentialsFromData["apiKey"],
      };

      // Only include message in the request body if it exists
      const feedbackRequestBody = {
        type: feedbackType,
        ...(message && { message: message }),
      };

      axios
        .put(
          `https://api.recommender.gigalogy.com/v1/gpt/conversations/${conversationId}`,
          feedbackRequestBody,
          { headers }
        )
        .then((response) => {
          console.log(response.data);
        });
    },
  },
  watch: {
    progressCalculate: {
      handler(newValue) {
        this.animateProgress(newValue);
      },
      immediate: true,
      deep: true,
    },
    currentStep(newStep) {
      if (newStep === 20) {
        this.isShowTasteProfileSectionMessage = true;
        // Hide the component after 3 seconds (3000 ms)
        // setTimeout(() => {
        //   this.isShowTasteProfileSectionMessage = false;
        // }, 3000);
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
.v-progress-linear {
  transition: width ease-in-out;
}

/* Shutter Out Horizontal */
.hvr-shutter-out-horizontal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  background: transparent;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.hvr-shutter-out-horizontal:before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #2098d1;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.hvr-shutter-out-horizontal.active {
  color: white;
}

.hvr-shutter-out-horizontal.active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

/* Sweep To Right */
.hvr-sweep-to-right {
  padding: 20px;
  background: lightgrey;
  display: inline-block;
  transform: perspective(1px) translateZ(0);
  position: relative;
  transition: color 0.3s;
}

.hvr-sweep-to-right:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: #2098d1;
  transform: scaleX(0);
  transform-origin: 0 50%;
  transition: transform 0.3s ease-out;
}

.hvr-sweep-to-right.active {
  color: white;
}

.hvr-sweep-to-right.active:before {
  transform: scaleX(1);
}

/* Center the taste-profile-start-content container */
.taste-profile-start-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-size: 18px;
}

/* Button styling with hover gradient effect */
.continue-btn {
  padding: 5px 20px;
  background: transparent;
  color: white;
  border: 1px solid whitesmoke;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

/* Gradient hover effect */
.continue-btn:hover {
  background: linear-gradient(to right, #07f7f7, transparent);
}

/* Custom styles with Vuetify class enhancements */
.questionnaire-container {
  /* Title font sizes */
  --title-font-size-desktop: 1.5rem;
  /* Baseline for desktop */
  --title-font-size-tablet: 1.2rem;
  /* 1.5rem ÷ 1.25 */
  --title-font-size-mobile: 0.96rem;
  /* 1.2rem ÷ 1.25 */

  /* Question font sizes */
  --question-font-size-desktop: 1.2rem;
  /* Baseline for desktop */
  --question-font-size-tablet: 0.96rem;
  /* 1.2rem ÷ 1.25 */
  --question-font-size-mobile: 0.77rem;
  /* 0.96rem ÷ 1.25 */

  /* Option font sizes */
  --option-font-size-desktop: 1rem;
  /* Baseline for desktop */
  --option-font-size-tablet: 0.9rem;
  /* 1rem ÷ 1.25 */
  --option-font-size-mobile: 0.74rem;
  /* 0.8rem ÷ 1.25 */
}

/* Enhanced Title Styles */
.questionnaire-title {
  font-size: var(--title-font-size-desktop) !important;
  transition: all 0.3s ease;
  padding: 16px 20px !important;
}

/* Question Text Enhancement */
.question-text {
  font-size: var(--question-font-size-desktop);
  margin-bottom: 0.5rem;
  transition: all 0.3s ease;
}

.option-text {
  font-size: var(--option-font-size-desktop) !important;
  transition: font-size 0.3s ease;
}

@media (max-width: 600px) {
  .questionnaire-title {
    font-size: var(--title-font-size-mobile) !important;
    padding: 12px !important;
  }

  .question-text {
    font-size: var(--question-font-size-mobile) !important;
    margin-bottom: 0.25rem;
  }

  .option-text /deep/ .v-label {
    font-size: var(--option-font-size-mobile) !important;
  }
}

.alignment {
  align-items: center;
}
</style>
