export const dummyFullRecommendations = {
  "request_id": "df3a30df-0f3b-4da9-a935-7fa47a41573e",
  "detail": {
    "recommendations": {
      "辛口": [
        {
          "JANコード": "4930391140473",
          "商品名": "菊水 涼風",
          "score": 85,
          "images": "https://kikusui-stg.s3.ap-northeast-1.amazonaws.com/%E5%95%86%E5%93%81%E7%94%BB%E5%83%8F/%E8%8F%8A%E6%B0%B4%E6%B6%BC%E9%A2%A8720ml%E3%82%B5%E3%82%A4%E3%82%B9%E3%82%99%E5%B0%8F.png",
          "商品説明(60文字)": "暑さがこたえる日本の夏。日中の耐え難い日射の強さと息苦し\nい空気。そんな真夏の一日の終わりに、身体を癒すひとときに\n相応しい透明感のあるお酒です。ひと口飲めば、まるで喉に\n「涼風」が吹き抜けていく、そんな清涼感をお楽しみください。\n菊水涼風は、リンゴ酸を多産する酵母を使用しています(リン\nゴ酸高生産性酵母)。白ワインに多く含まれるリンゴ酸が奏で\nるボリューム感ある酸味と甘みが調和しています。",
          "参考価格": 1088,
          "甘辛区分": [
            "辛口"
          ],
          "特定名称": [],
          "飲み頃温度": [
            "◎冷やして"
          ]
        },
        {
          "JANコード": "4930391610488",
          "商品名": "菊水 無冠帝",
          "score": 90,
          "images": "https://kikusui-stg.s3.ap-northeast-1.amazonaws.com/%E5%95%86%E5%93%81%E7%94%BB%E5%83%8F/%E7%84%A1%E5%86%A0%E5%B8%9D720ml_L.png",
          "商品説明(60文字)": "ひかえめな香り、さらりと喉を通る心地よさ。それでいてキレが\nあるのは、旨みの中にしっかりとした辛さがあるから。",
          "参考価格": 1389,
          "甘辛区分": [
            "辛口"
          ],
          "特定名称": [
            "吟醸"
          ],
          "飲み頃温度": [
            "○オンザロック/◎冷やして/○常温"
          ]
        },
        {
          "JANコード": "4930391110735",
          "商品名": "菊水 純米吟醸 限定酒",
          "score": 88,
          "images": "https://kikusui-stg.s3.ap-northeast-1.amazonaws.com/%E5%95%86%E5%93%81%E7%94%BB%E5%83%8F/%E8%8F%8A%E6%B0%B4%E7%B4%94%E7%B1%B3%E5%90%9F%E9%86%B8%E9%99%90%E5%AE%9A%E9%85%92720_L.png",
          "商品説明(60文字)": "菊水酒造の酒銘でもある「菊の水」は古くから不老長寿をもた\nらす霊力があると信じられています。菊水 純米吟醸 限定酒\nは、皆さまの健康・長寿を祈願し、蔵出しいたしました。純米吟\n醸ならではのまろやかな旨みが口中で膨らみ優しく調和するお\n酒に仕上げています。",
          "参考価格": 1580,
          "甘辛区分": [
            "辛口"
          ],
          "特定名称": [],
          "飲み頃温度": [
            "◎冷やして、○常温\n\n"
          ]
        }
      ],
      "甘口": [
        {
          "JANコード": "4930391150120",
          "商品名": "五郎八",
          "score": 75,
          "images": "https://kikusui-stg.s3.ap-northeast-1.amazonaws.com/%E5%95%86%E5%93%81%E7%94%BB%E5%83%8F/%E4%BA%94%E9%83%8E%E5%85%AB720_L.png",
          "商品説明(60文字)": "米の旨味が丸ごと味わえる、豪快ながらも素朴な味わい。じっ\nくり旨味が染みわたり、体を芯から温めてくれます。",
          "参考価格": 914,
          "甘辛区分": [
            "甘口"
          ],
          "特定名称": [],
          "飲み頃温度": [
            "◎オンザロック",
            "◎冷やして"
          ]
        },
        {
          "JANコード": "4930391140527",
          "商品名": "菊水 涼風うすにごり",
          "score": 80,
          "images": "https://kikusui-stg.s3.ap-northeast-1.amazonaws.com/%E5%95%86%E5%93%81%E7%94%BB%E5%83%8F/%E8%8F%8A%E6%B0%B4%E6%B6%BC%E9%A2%A8%E3%81%86%E3%81%99%E3%81%AB%E3%81%93%E3%82%99%E3%82%8A_L.png",
          "商品説明(60文字)": "リンゴ酸高生産性酵母を使用し、酵母由来の酸味とやさしい甘 みが調和したうすにごり酒。 \nなめらかな舌触りとキレのある味わいで、軽快な飲み心地が 夏の暑さを和らげ、身も心も癒します。よく冷やし、軽く上下に 振り、澱をからめてお召し上がりください。\n\n",
          "参考価格": 1166,
          "甘辛区分": [
            "甘口"
          ],
          "特定名称": [],
          "飲み頃温度": [
            "◎冷やして\n\n"
          ]
        }
      ],
      "中口": [
        {
          "JANコード": "4930391190126",
          "商品名": "節五郎出品酒",
          "score": 95,
          "images": "https://kikusui-stg.s3.ap-northeast-1.amazonaws.com/%E5%95%86%E5%93%81%E7%94%BB%E5%83%8F/%E7%84%A1%E5%86%A0%E5%B8%9D720ml_L.png",
          "商品説明(60文字)": "コンテストへの出品を目指して醸した大吟醸。華やかな香りと\n旨味の広がる奥深い味わいが見事に調和し、次の一口をまた\n飲みたい、そう感じさせる渾身の一本。",
          "参考価格": 3258,
          "甘辛区分": [
            "中口"
          ],
          "特定名称": [
            "大吟醸原酒"
          ],
          "飲み頃温度": [
            "◎冷やして"
          ]
        }
      ],
      "やや辛口": [
        {
          "JANコード": "4930391620258",
          "商品名": "蔵光",
          "score": 98,
          "images": "https://kikusui-stg.s3.ap-northeast-1.amazonaws.com/%E5%95%86%E5%93%81%E7%94%BB%E5%83%8F/New_%E8%94%B5%E5%85%89_750ml_(%E5%9B%BD%E5%86%85)_L.png",
          "商品説明(60文字)": "菊水の知と技を全て注ぎ込んだ渾身の一本。気品溢れる高貴\nな香り、雑味を排除したシルクのような口当たり、極上のくちど\nけ。",
          "参考価格": 11000,
          "甘辛区分": [
            "やや辛口"
          ],
          "特定名称": [
            "純米大吟醸"
          ],
          "飲み頃温度": [
            "◎冷やして/◎常温"
          ]
        },
        {
          "JANコード": "4930391120833",
          "商品名": "菊水の純米酒",
          "score": 85,
          "images": "https://kikusui-stg.s3.ap-northeast-1.amazonaws.com/%E5%95%86%E5%93%81%E7%94%BB%E5%83%8F/%E8%8F%8A%E6%B0%B4%E3%81%AE%E7%B4%94%E7%B1%B3%E9%85%92720_L.png",
          "商品説明(60文字)": "お米の旨味が感じられる濃厚な旨口。濃厚なのに重くなく、そ\nれでいてしっかりとしたコクがあります。",
          "参考価格": 1085,
          "甘辛区分": [
            "やや辛口"
          ],
          "特定名称": [
            "純米"
          ],
          "飲み頃温度": [
            "○冷やして/○常温/◎人肌燗/○熱燗"
          ]
        },
        {
          "JANコード": "4930391120208",
          "商品名": "酒米菊水 純米大吟醸",
          "score": 92,
          "images": "https://kikusui-stg.s3.ap-northeast-1.amazonaws.com/%E5%95%86%E5%93%81%E7%94%BB%E5%83%8F/%E9%85%92%E7%B1%B3%E8%8F%8A%E6%B0%B4%E7%B4%94%E7%B1%B3%E5%A4%A7%E5%90%9F%E9%86%B8720ml_%E3%83%9B%E3%82%99%E3%83%88%E3%83%AB%E6%AD%A3%E9%9D%A2_L.png",
          "商品説明(60文字)": "「雄町」の血を継いだ幻の酒米『菊水』を復活させ、蔵元『菊水』 が醸した純米大吟醸酒。芳醇な香りと深くやわらかな辛口。\n\n",
          "参考価格": 2542,
          "甘辛区分": [
            "やや辛口"
          ],
          "特定名称": [
            "純米大吟醸"
          ],
          "飲み頃温度": [
            "◎冷やして",
            "○常温",
            "○人肌燗\n\n"
          ]
        }
      ],
      "やや甘口": [
        {
          "JANコード": "4930391710003",
          "商品名": "紅菊水",
          "score": 87,
          "images": "https://kikusui-stg.s3.ap-northeast-1.amazonaws.com/%E5%95%86%E5%93%81%E7%94%BB%E5%83%8F/%E7%B4%85%E8%8F%8A%E6%B0%B4720ml.jpg",
          "商品説明(60文字)": "新潟県産の梅を菊水の純米酒へ漬け込み 梅の風味がたっ ぷりとしみ込み 押しのある酸味と芳酔な甘みが程よく調和し た逸品。",
          "参考価格": 2124,
          "甘辛区分": [
            "やや甘口"
          ],
          "特定名称": [],
          "飲み頃温度": [
            "◎オンザロック",
            "◎冷やして"
          ]
        },
        {
          "JANコード": "4930391130818",
          "商品名": "菊水の淡麗甘口",
          "score": 78,
          "images": "https://kikusui-stg.s3.ap-northeast-1.amazonaws.com/%E5%95%86%E5%93%81%E7%94%BB%E5%83%8F/%E8%8F%8A%E6%B0%B4%E3%81%AE%E6%B7%A1%E9%BA%97%E7%94%98%E5%8F%A3720_L.png",
          "商品説明(60文字)": "通常より多くの米を使い、甘口に仕上げています。贅沢に使っ\nた米から醸し出される甘味は、料理をやさしく引き立ててくれま\nす。",
          "参考価格": 964,
          "甘辛区分": [
            "やや甘口"
          ],
          "特定名称": [
            "本醸造"
          ],
          "飲み頃温度": [
            "○オンザロック/○冷やして/○常温/○人肌燗"
          ]
        },
        {
          "JANコード": "4930391141029",
          "商品名": "菊水 しぼりたて生原酒",
          "score": 82,
          "images": "https://kikusui-stg.s3.ap-northeast-1.amazonaws.com/%E5%95%86%E5%93%81%E7%94%BB%E5%83%8F/%E3%81%97%E3%81%BB%E3%82%99%E3%82%8A%E3%81%9F%E3%81%A6%E7%94%9F%E5%8E%9F%E9%85%92720ml_%EF%BC%AC.png",
          "商品説明(60文字)": "しぼりたてのお酒を一切加熱殺菌することなく閉じ込めまhし\nた。麹由来の香り、お酒の旨みが活きたフレッシュな一本",
          "参考価格": 1376,
          "甘辛区分": [
            "やや甘口"
          ],
          "特定名称": [
            "本醸造"
          ],
          "飲み頃温度": [
            "◎オンザロック",
            "◎冷やして"
          ]
        }
      ]
    }
  }
}


export const dummyPartialRecommendations = {
  "request_id": "df3a30df-0f3b-4da9-a935-7fa47a41573e",
  "detail": {
    "recommendations": {
      "辛口": [],
      "甘口": [
        {
          "JANコード": "4930391150120",
          "商品名": "五郎八",
          "score": 75,
          "images": "https://kikusui-stg.s3.ap-northeast-1.amazonaws.com/%E5%95%86%E5%93%81%E7%94%BB%E5%83%8F/%E4%BA%94%E9%83%8E%E5%85%AB720_L.png",
          "商品説明(60文字)": "米の旨味が丸ごと味わえる、豪快ながらも素朴な味わい。じっ\nくり旨味が染みわたり、体を芯から温めてくれます。",
          "参考価格": 914,
          "甘辛区分": [
            "甘口"
          ],
          "特定名称": [],
          "飲み頃温度": [
            "◎オンザロック",
            "◎冷やして"
          ]
        },
        {
          "JANコード": "4930391140527",
          "商品名": "菊水 涼風うすにごり",
          "score": 80,
          "images": "https://kikusui-stg.s3.ap-northeast-1.amazonaws.com/%E5%95%86%E5%93%81%E7%94%BB%E5%83%8F/%E8%8F%8A%E6%B0%B4%E6%B6%BC%E9%A2%A8%E3%81%86%E3%81%99%E3%81%AB%E3%81%93%E3%82%99%E3%82%8A_L.png",
          "商品説明(60文字)": "リンゴ酸高生産性酵母を使用し、酵母由来の酸味とやさしい甘 みが調和したうすにごり酒。 \nなめらかな舌触りとキレのある味わいで、軽快な飲み心地が 夏の暑さを和らげ、身も心も癒します。よく冷やし、軽く上下に 振り、澱をからめてお召し上がりください。\n\n",
          "参考価格": 1166,
          "甘辛区分": [
            "甘口"
          ],
          "特定名称": [],
          "飲み頃温度": [
            "◎冷やして\n\n"
          ]
        }
      ],
      "中口": [],
      "やや辛口": [
        {
          "JANコード": "4930391620258",
          "商品名": "蔵光",
          "score": 98,
          "images": "https://kikusui-stg.s3.ap-northeast-1.amazonaws.com/%E5%95%86%E5%93%81%E7%94%BB%E5%83%8F/New_%E8%94%B5%E5%85%89_750ml_(%E5%9B%BD%E5%86%85)_L.png",
          "商品説明(60文字)": "菊水の知と技を全て注ぎ込んだ渾身の一本。気品溢れる高貴\nな香り、雑味を排除したシルクのような口当たり、極上のくちど\nけ。",
          "参考価格": 11000,
          "甘辛区分": [
            "やや辛口"
          ],
          "特定名称": [
            "純米大吟醸"
          ],
          "飲み頃温度": [
            "◎冷やして/◎常温"
          ]
        },
        {
          "JANコード": "4930391120833",
          "商品名": "菊水の純米酒",
          "score": 85,
          "images": "https://kikusui-stg.s3.ap-northeast-1.amazonaws.com/%E5%95%86%E5%93%81%E7%94%BB%E5%83%8F/%E8%8F%8A%E6%B0%B4%E3%81%AE%E7%B4%94%E7%B1%B3%E9%85%92720_L.png",
          "商品説明(60文字)": "お米の旨味が感じられる濃厚な旨口。濃厚なのに重くなく、そ\nれでいてしっかりとしたコクがあります。",
          "参考価格": 1085,
          "甘辛区分": [
            "やや辛口"
          ],
          "特定名称": [
            "純米"
          ],
          "飲み頃温度": [
            "○冷やして/○常温/◎人肌燗/○熱燗"
          ]
        },
        {
          "JANコード": "4930391120208",
          "商品名": "酒米菊水 純米大吟醸",
          "score": 92,
          "images": "https://kikusui-stg.s3.ap-northeast-1.amazonaws.com/%E5%95%86%E5%93%81%E7%94%BB%E5%83%8F/%E9%85%92%E7%B1%B3%E8%8F%8A%E6%B0%B4%E7%B4%94%E7%B1%B3%E5%A4%A7%E5%90%9F%E9%86%B8720ml_%E3%83%9B%E3%82%99%E3%83%88%E3%83%AB%E6%AD%A3%E9%9D%A2_L.png",
          "商品説明(60文字)": "「雄町」の血を継いだ幻の酒米『菊水』を復活させ、蔵元『菊水』 が醸した純米大吟醸酒。芳醇な香りと深くやわらかな辛口。\n\n",
          "参考価格": 2542,
          "甘辛区分": [
            "やや辛口"
          ],
          "特定名称": [
            "純米大吟醸"
          ],
          "飲み頃温度": [
            "◎冷やして",
            "○常温",
            "○人肌燗\n\n"
          ]
        }
      ],
      "やや甘口": []
    }
  }
}